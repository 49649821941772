import React,{Component} from 'react'

export default class Sns extends Component {
	share(e, link){
		e.preventDefault()
		window.open(link,"social_window","width=600,height=600,resizable=yes,scrollbars=yes,toolbar=yes")
	}

	render(){
		return(
			<div className="mv-sns pc">
				<div className="mv-sns-share helvetica">
					<span className="animated fadeInLeft delay-4s">Share</span>
					<div className="mv-sns-share-img">
						<a href="https://twitter.com/share?url=https://walogical.com" onClick={(e)=>this.share(e,'https://twitter.com/share?url=https://walogical.com')} className="animated fadeInUp delay-4s">
							<img class="sp" src="assets/img/svg-05.svg"/>
							<img class="pc" src="assets/img/icon-30.svg"/>
						</a>
						<a href="https://www.facebook.com/sharer/sharer.php?u=https://walogical.com" style={{zIndex: 6}} onClick={(e)=>this.share(e,'https://www.facebook.com/sharer/sharer.php?u=https://walogical.com')} className="animated fadeInUp delay-4s">
							<img src="assets/img/svg-06.svg"/>
						</a>
						<a href="http://line.me/R/msg/text/?https://walogical.com" onClick={(e)=>this.share(e,'http://line.me/R/msg/text/?https://walogical.com')} className="animated fadeInUp delay-4s">
							<img class="sp" src="assets/img/svg-07.svg"/>
							<img class="pc" src="assets/img/icon-31.png"/>
						</a>
					</div>
				</div>
			</div>
		)
	}
}