import React,{Component} from 'react'

export default class Notes extends Component{
	constructor(props){
		super(props)
	}

	render(){
		return(
			<section id="notes">
				<div className="notes-move pc">
					<span className="notes-move-bulletL l01"></span>
					<span className="notes-move-bulletM m01"></span>
					<span className="notes-move-bulletS s01"></span>
					<span className="notes-move-asteroid a01"></span>
					<span className="notes-move-human h01"></span>
					<span className="notes-move-human h02"></span>
				</div>
				<div className="container">
					<div className="scrollmagic parentScrollMagic" data-scrollmagic="b">
						<div className="notes-title childScrollMagic" data-scrollmagic="b">
							<img className="pc" src="assets/img/text-13.png"/>
							<img className="sp" src="assets/img/text-26.png"/>
						</div>
						<div className="notes-text">
							<ul>
								<li className="childScrollMagic" data-scrollmagic="b" data-delay="300">
									お笑いの「<span>技能</span>」をご利用ください。<br className="sp"/>お笑い芸人としてのご利用はご遠慮ください。
								</li>
								<li className="childScrollMagic" data-scrollmagic="b" data-delay="500">
									代理の方を介してではなく、できるだけ<br className="sp"/>「<span>当事者の方が直接</span>」ご相談ください。
								</li>
								<li className="childScrollMagic" data-scrollmagic="b" data-delay="700">
									精一杯の努力を致しますが、<br className="sp"/>サービスの性質上「<span>スベる</span>」場合もあります。
								</li>
							</ul>
						</div>
						<div className="notes-info childScrollMagic" data-scrollmagic="b" data-delay="900">
							<ul>
								<li>
									「笑ロジカル」所属メンバーの事前公開及び、特定のメンバー指定によるご用命はお受けしておりません。
								</li>
								<li>
									「笑ロジカル」所属メンバーの「広告出演契約」や「イベント出演契約」を希望される場合は、芸能活動として所属するマネジメント会社をご紹介させていただきます。
								</li>
								<li>
									法人・個人を問わずご利用いただけますが、当サービスの運営会社が規定する審査がございます。また、ご相談の内容やスケジュールによってはサービスのご提供をご辞退させていただく場合があります。
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		)
	}
}