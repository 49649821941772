import React, {Component} from "react"
import Sns from "../component/sns"

export default class Mv extends Component {
	componentDidMount(){
		this.scrollShow()
		this.svg()
	}

	scrollShow(){
		let $target = $('.mv-contact a')

		$(window).on('load scroll', function() {
			if ($(window).scrollTop() > 5100) {
				$target.removeClass('anim')
			}
			else{
				$target.addClass('anim')
			}
		});
	}

	svg(){
		$('#bigLogo').find('.cls-1').css({fill: '#fff'})
		$('#bigLogo').find('.cls-2').css({fill: '#fff'})
		$('#bigLogo').find('.cls-3').css({fill: '#fff'})

		setTimeout(()=>{
			$('#bigLogo').find('.cls-1').css({fill: '#e0600e'})
			$('#bigLogo').find('.cls-2').css({fill: '#fff'})
			$('#bigLogo').find('.cls-3').css({fill: '#212222'})
		},2500)
	}

	render(){
		return(
			<section id="mv">
				<div className="mv-move">
					<span className="mv-move-bulletL l01 pc"></span>
					<span className="mv-move-bulletL l02 pc"></span>
					<span className="mv-move-bulletL l03 pc"></span>
					<span className="mv-move-bulletL l04 pc"></span>
					<span className="mv-move-bulletM m01 pc"></span>
					<span className="mv-move-bulletM m02"></span>
					<span className="mv-move-bulletM m03"></span>
					<span className="mv-move-bulletS s01"></span>
					<span className="mv-move-bulletS s02"></span>
					<span className="mv-move-bulletS s03 pc"></span>
					<span className="mv-move-bulletS s04 pc"></span>
					<span className="mv-move-bulletS s05 pc"></span>
					<span className="mv-move-bulletS s06 pc"></span>
					<span className="mv-move-asteroid a01"></span>
					<span className="mv-move-asteroid a02"></span>
					<span className="mv-move-human h01"></span>
					<span className="mv-move-human h02 pc"></span>
					<span className="mv-move-human h03 pc"></span>
					<span className="mv-move-human h04"></span>
					<span className="mv-move-satelite st01"></span>
					<span className="mv-move-bottle b01"></span>
				</div>
				<div className="container">
					<Sns/>
					<div className="mv-logo">
						<img src="assets/img/svg-02.svg"/>
					</div>
					<div className="mv-contact pc">
						<a href="#contact">お問合せ</a>
					</div>
				</div>
			</section>
		)
	}
}