import React,{Component} from 'react'

export default class Footer extends Component{
	render(){
		return(
			<footer id="footer">
				<div className="container">
					<div className="footer-link">
						<ul>
							<li>
								<a href="http://www.donescience.com/" target="_blank">「笑ロジカル」運営会社　ダン:サイエンス</a>
							</li>
							<li>
								<a href="http://www.donescience.com/privacy-policy/" target="_blank">個人情報保護方針</a>
							</li>
							<li>
								<a href="http://www.donescience.com/handling-of-privacy/" target="_blank">個人情報の取り扱いについて</a>
							</li>
						</ul>
					</div>
					<div className="footer-copy">
						<span>「笑ロジカル」はダン:サイエンス株式会社の登録商標です</span>
						<span>Copyright © Done Science co.,ltd. All Rights Reserved.</span>
					</div>
				</div>
			</footer>
		)
	}
}