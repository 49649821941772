import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

export default class ContactForm extends Component {
	constructor(props){
		super(props)
		this.state = {
			subject: "お仕事のご相談",
			company: "",
			name: "",
			email: "",
			phone: "",
			content: "",
			error: [],
			status: false,
			phase: 1,
			phpError: [],
			loading: false
		}
	}

	subjectInput(value){
		this.setState({
			subject: value
		})
	}
	companyInput(e){
		this.setState({
			company: e.target.value
		})
	}
	nameInput(e){
		this.setState({
			name: e.target.value
		})
	}
	emailInput(e){
		this.setState({
			email: e.target.value
		})
	}
	phoneInput(e){
		this.setState({
			phone: e.target.value
		})
	}
	contentInput(e){
		this.setState({
			content: e.target.value
		})
	}

	validateInput(){
		let tempError = []
		const {subject, company, name , email, phone, content, error, phpError} = this.state

		if(tempError.length === 0){
			//axios post data
			let formdata = new FormData()

			formdata.append('subject', subject)
			formdata.append('company', company)
			formdata.append('name', name)
			formdata.append('email', email)
			formdata.append('phone', phone)
			formdata.append('content', content)

			axios.post('contact/index.php', formdata)
		  .then((result)=>{
				if(result.data.response === 200){
					this.setState({
						subject: result.data.body['subject'],
						company: result.data.body['company'],
						name: result.data.body['name'],
						email: result.data.body['email'],
						phone: result.data.body['phone'],
						content: result.data.body['content'],
						phase: 2,
						status: true,
						phpError: []
					})
				}
				else if(result.data.response === 400){
					this.setState({
						phpError: result.data.body
					})
				}
			})
		  .catch((e)=>{
				console.log(e)
			})
		}

	}

	backBtn(){
		this.setState({
			phase: 1,
			status: false,
		})
	}

	sendBtn(){
		// WARNING: validation PHP + メール送信
		const {subject, company, name , email, phone, content, loading} = this.state
		let formdata = new FormData()

		formdata.append('subject', subject)
		formdata.append('company', company)
		formdata.append('name', name)
		formdata.append('email', email)
		formdata.append('phone', phone)
		formdata.append('content', content)

		if(!loading){
			this.setState({
				loading: true
			})
			$('html').css({height: '100%', overflow: 'hidden'})
			axios.post('contact/send.php', formdata)
			.then((result)=>{
				if(result.data.response === 200){
					console.log(result.data.body)
					this.setState({
						phase: 3,
						subject: "お仕事のご相談",
						company: "",
						name: "",
						email: "",
						phone: "",
						content: "",
						error: [],
						loading: false
					})
					$('html').css({height: '', overflow: ''})
				}
				else if(result.data.response === 400){
					console.log("Error")
					this.setState({
						phase: 3,
						subject: "お仕事のご相談",
						company: "",
						name: "",
						email: "",
						phone: "",
						content: "",
						error: [],
						loading: false
					})
					$('html').css({height: '', overflow: ''})
				}
			})
			.catch((e)=>{
				$('html').css({height: '', overflow: ''})
				console.log(e)
			})
		}

	}

	render(){
		let {
			subject,
			company,
			name,
			email,
			phone,
			content,
			error,
			phase,
			status,
			phpError,
			loading
		} = this.state

		return(
			<React.Fragment>
				<div id="loaderWp" className={!loading ? "noLoad" : ""}>
					<div className="loader"></div>
				</div>
				<section id="contact">
					<div className="contact-move pc">
						<span className="contact-move-bulletM m01"></span>
						<span className="contact-move-bulletS s01"></span>
						<span className="contact-move-bulletS s02"></span>
						<span className="contact-move-bulletS s03"></span>
						<span className="contact-move-bulletS s04"></span>
						<span className="contact-move-human contact-move-human-parallax h01"></span>
						<span className="contact-move-human contact-move-human-parallax h02"></span>
						<span className="contact-move-human h03"></span>
						<span className="contact-move-human h04"></span>
						<span className="contact-move-human h05"></span>
						<span className="contact-move-asteroid a01"></span>
					</div>
					<div className="container">
						<div className="contact-title scrollmagic" data-scrollmagic="b">
							<img className="pc" src="assets/img/text-28.png" alt="CONTACT お問い合わせ"/>
							<img className="sp" src="assets/img/text-27.png" alt="CONTACT お問い合わせ"/>
						</div>
						<div className="contact-exp scrollmagic" data-scrollmagic="b">
							<p>
								下記に必要事項をご記入のうえ、送信してください。<br/>
								内容によっては回答をさしあげるのにお時間をいただくこともございます。予めご了承ください。
							</p>
						</div>
					</div>

					<div className="scrollmagic" data-scrollmagic="b">
						<div className="contact-timeline">
							<span className={phase === 1 ? "active" : ""}>1</span>
							<span className={phase === 2 ? "active" : ""}>2</span>
							<span className={phase === 3 ? "active" : ""}>3</span>
						</div>
						<div className={phase !== 3 ? "contact-form unhide" : "hide contact-form"}>

							<dl>
								<dt>
									<label>お問合せ項目</label>
									<span className="required"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<div className="radio-wrapper">
										<div onClick={()=>this.subjectInput('お仕事のご相談')} className={subject == 'お仕事のご相談' ? 'radio-content active' : 'radio-content' }>
											<input type="radio" value="お仕事のご相談" />お仕事のご相談
										</div>
										<div onClick={()=>this.subjectInput('サービスや料金について')} className={subject == 'サービスや料金について' ? 'radio-content active' : 'radio-content' }>
											<input type="radio" value="サービスや料金について" />サービスや料金について
										</div>
										<div onClick={()=>this.subjectInput('その他')} className={subject == 'その他' ? 'radio-content active' : 'radio-content' }>
											<input type="radio" value="その他" />その他
										</div>
									</div>
									<span className="errorMsg">{phpError.subject ? phpError.subject : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p>{subject}</p>
								</dd>
							</dl>
							<dl>
								<dt>
									<label>会社・団体名<span>(個人の方は氏名）</span></label>
									<span className="required"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<input className={error || phpError.company ? error.includes('company') || phpError.company ? "err" : "" : ""} onChange={(e)=>this.companyInput(e)} id="company" type="text" name="company" placeholder="例) ○○○株式会社" required/>
									<span className="errorMsg errorMsgCmp">{phpError.company ? phpError.company : error.includes('company') ? '入力必須項目です' : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p>{company}</p>
								</dd>
							</dl>
							<dl>
								<dt>
									<label>ご担当者名</label>
									<span className="option"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<input className={phpError.name ? "err" : ''} onChange={(e)=>this.nameInput(e)} id="name" type="text" name="name" placeholder="例) 山田 太郎"/>
									<span className="errorMsg">{phpError.name ? phpError.name : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p>{name}</p>
								</dd>
							</dl>
							<dl>
								<dt>
									<label>メールアドレス</label>
									<span className="required"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<input className={error || phpError.email ? error.includes('email') || phpError.email ? "err" : "" : ""} onChange={(e)=>this.emailInput(e)} id="email" type="text" name="email" placeholder="例）abcd@efg.com" required/>
									<span className="errorMsg">{phpError.email ? phpError.email : error.includes('email') ? '入力必須項目です' : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p>{email}</p>
								</dd>
							</dl>
							<dl>
								<dt>
									<label>お電話番号</label>
									<span className="option"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<input className={phpError.phone ? "err" : ''} onChange={(e)=>this.phoneInput(e)} id="phone" type="text" name="phone" placeholder="例) 0123456789"/>
									<span className="errorMsg">{phpError.phone ? phpError.phone : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p>{phone}</p>
								</dd>
							</dl>
							<dl className="last-child">
								<dt>
									<label>お問合せ内容</label>
									<span className="option"></span>
								</dt>
								<dd className={!status ? "unhide" : "hide"}>
									<textarea className={phpError.content ? "err" : ''} onChange={(e)=>this.contentInput(e)} id="content" type="text" name="content" placeholder="ご入力ください"/>
									<span className="errorMsg">{phpError.content ? phpError.content : ''}</span>
								</dd>
								<dd className={status ? "unhide confirmBox" : "hide"}>
									<p style={{backgroundColor: "#fff", color: "#000",opacity: 1}}>{content}</p>
								</dd>
							</dl>
							<div className={!status ? "contact-privacy unhide" : "hide contact-privacy"}>
								個人情報の取扱いについては、<a href="http://www.donescience.com/privacy-policy/" target="_blank">プライバシーポリシー<img src="assets/img/icon-01.png"/></a>をご覧ください。<br className="pc"/>
								ご同意いただける場合のみ「送信内容を確認する」ボタンにて次へお進みください。
							</div>
							<div className={!status ? "contact-btn unhide" : "hide contact-btn"}>
								<button onClick={()=>this.validateInput()}>送信内容を確認する</button>
							</div>
							<div className={status ? "contact-send unhide" : "hide contact-send"}>
								<button onClick={()=>this.backBtn()}>修正する</button>
								<button className={loading ? 'loading' : ''} onClick={()=>this.sendBtn()}>送信する</button>
							</div>
						</div>
						<div className={phase === 3 ? "contact-thanks unhide" : "hide contact-thanks"}>
							<p>お問合せありがとうございました。</p>
						</div>
						<div className="contact-phone">
							<a className="pc contact-link" href="tel:03-6225-2855">
								<span className="pc link-01">お急ぎの方は<br/>こちら</span>
								<span className="pc link-02">（平日9:30～18:30）</span>
								<span className="pc link-03">運営会社に繋がります。「笑ロジカルの件で」とお伝えください。</span>
							</a>
							<span className="sp first">お急ぎの方はこちら</span>
							<a href="tel:03-6225-2855" className="sp second"><img src="assets/img/img-69.png"/></a>
							<span className="sp third">（平日9:30～18:30）</span>
							<span className="sp fourth">運営会社に繋がります。<br/>「笑ロジカルの件で」とお伝えください。</span>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}