import React,{Component} from 'react'

export default class Price extends Component{
	render(){
		return(
			<section id="price">
				<div className="container">
					<div className="scrollmagic parentScrollMagic" data-scrollmagic="B" data-delay="300">
						<div className="price-title childScrollMagic" data-scrollmagic="b">
							<img className="pc" src="assets/img/text-06.png"/>
							<img className="sp" src="assets/img/text-19.png"/>
						</div>
						<div className="price-img price-01 childScrollMagic" data-scrollmagic="b" data-delay="600">
							<div className="price-img-title"><span>サービス公開記念！先着５社(名)限定の出血サービス！</span>「おためし笑ロジカル」</div>
							<div className="price-img-cnt">
								<div className="price-img-cnt-list">
									<span className="txt">打合せや会議の参加</span>
									<div className="col">
										<div className="num">
											<div>
												<span class="first">50</span><span class="second">%</span><br/>
												<span class="third">OFF</span>
											</div>
										</div>
										<div className="off">
											<span class="strip">¥ 20,000 ~</span>
											<span class="now">¥10,000<span>~</span></span>
										</div>
									</div>
								</div>
								<div className="price-img-cnt-list">
									<span className="txt">スピーチなどの台本</span>
									<div className="col">
										<div className="num">
											<div>
												<span class="first">40</span><span class="second">%</span><br/>
												<span class="third">OFF</span>
											</div>
										</div>
										<div className="off">
											<span class="strip">¥ 50,000 ~</span>
											<span class="now">¥30,000<span>~</span></span>
										</div>
									</div>
								</div>
								<div className="price-img-cnt-list">
									<span className="txt">名称やキャッチコピー</span>
									<div className="col">
										<div className="num">
											<div>
												<span class="first">50</span><span class="second">%</span><br/>
												<span class="third">OFF</span>
											</div>
										</div>
										<div className="off">
											<span class="strip spc">¥ 100,000 ~</span>
											<span class="now">¥50,000<span>~</span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="price-img price-02 childScrollMagic" data-scrollmagic="b" data-delay="600">
						<div className="price-img-title">「しっかり笑ロジカル」</div>
						<div className="price-img-cnt">
							<div className="price-img-cnt-list">
								<span className="txt">ポスターやチラシの制作</span>
								<span className="img">
									<span className="bigPrice">¥200,000 ~</span>
								</span>
							</div>
							<div className="price-img-cnt-list">
								<span className="txt">販売、集客、宣伝の企画</span>
								<span className="img">
									<span className="bigPrice">¥300,000 ~</span>
								</span>
							</div>
							<div className="price-img-cnt-list">
								<span className="txt">商品やサービスを一緒につくる</span>
								<span className="img">
									<span className="bigPrice">¥ ASK</span>
								</span>
							</div>
						</div>
						</div>
						<div className="price-text childScrollMagic" data-scrollmagic="b" data-delay="1000">
							<p>上記のメニューだけでなく、「メンバーに従業員向けのセミナーをやって欲しい」「商店街を見学して、改善点を指摘して欲しい」<br class="pc"/>「人を笑わせるコツを家庭教師して欲しい」「闇営業に行きたくない」など、あらゆる“お題”をお受けします。
								<br/>また、ご予算については“月額制”や“成功報酬”など柔軟に対応させて頂いております。
							</p>
							<p>まずはお気軽に、ご相談ご連絡ください！</p>
						</div>
						<div className="price-info childScrollMagic" data-scrollmagic="b" data-delay="1000">
							<p>【料金全般について】</p>
							<span className="list">上記メニュー別の料金はあくまで目安です。<br/>お客さまの状況や解決したい課題の内容、使える時間、必要なアイデアの数、難易度などに応じた「お見積り」をさせて頂きます。</span>
							<span className="list">お見積には、プロジェクトの進行に必要となる、運営スタッフ人件費・諸経費・移動交通費・雑費等を追加させて頂きます。</span>
							<span className="list">運営会社の審査により、一部の法人様や個人の方については「前払い」のご相談をさせて頂く場合があります。</span>
							<span className="list">ご発注後の「キャンセル費」につきましては、お見積り時に記載させて頂きます。</span>
							<span className="list">消費税は別途となります。</span>
						</div>
						<div className="price-info price-info-last childScrollMagic" data-scrollmagic="b" data-delay="1000">
							<p>【キャンペーン価格について】</p>
							<span className="list">お申込の状況については、非公開となります。</span>
							<span className="list">１社１商品または１サービスに限らせて頂きます。</span>
							<span className="list">ご提案するアイデアの数量や期限は指定することができません。</span>
						</div>
					</div>
				</div>
			</section>
		)
	}
}