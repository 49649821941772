import React, {Component} from 'react'

export default class Benefit extends Component{
	constructor(props){
		super(props)
		this.state = {
			device: "",
			breakpoint: 768
		}
	}

	componentDidMount(){
		this.device()
		this.addSlick()
	}

	device(){
		let {breakpoint} = this.state

		$(window).on('load resize', ()=>{
			if(window.innerWidth <= breakpoint){
				this.setState({
					device: "sp"
				})
			}
			else{
				this.setState({
					device: "pc"
				})
			}
		})
	}

	addSlick(){
		let {device, breakpoint} = this.state
		let $target = $('.benefit-slider')

		try {
			$target.slick({
				centerMode: true,
				slidesToShow: 1,
				infinite: false,
				arrows: false,
				centerPadding: '15%',
			})
		} catch (e) {
			console.log(e.message)
		}
	}

	render(){
		return(
			<section id="benefit">
				<div className="container">
					<div className="benefit-title scrollmagic" data-scrollmagic="b">
						<img className="pc" src="assets/img/text-04.png"/>
						<img className="sp" src="assets/img/text-16.png"/>
					</div>
					<div className="benefit-content first-child scrollmagic parentScrollMagic" data-scrollmagic="L">
						<div className="benefit-content-title childScrollMagic pc" data-scrollmagic="l" data-delay="200">
							そうきたか！商品やサービスの開発に<br class="sp"/><span>「<span className="yellow">アイデアのネタを</span>」</span>
						</div>
						<div className="benefit-content-title childScrollMagic sp" data-scrollmagic="l">
							そうきたか！商品やサービスの開発に<br class="sp"/><span>「<span className="yellow">アイデアのネタを</span>」</span>
						</div>
						<div className="benefit-content-img pc">
							<div className="childScrollMagic" data-scrollmagic="l" data-delay="300">
								<img src="assets/img/comic1-1.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l" data-delay="400">
								<img src="assets/img/comic1-2.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l" data-delay="500">
								<img src="assets/img/comic1-3.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l" data-delay="600">
								<img src="assets/img/comic1-4.gif"/>
							</div>
						</div>
						<div className="benefit-content-img sp benefit-slider">
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic1-1.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic1-2.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic1-3.gif"/>
							</div>
							<div className="childScrollMagic lastChildScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic1-4.gif"/>
							</div>
						</div>
						<div className="benefit-content-txt childScrollMagic pc" data-scrollmagic="l" data-delay="700">
							「笑ロジカル」が企画開発した「かいてくだサインバッジ」。<br className="pc"/>「新しい“缶バッジ”をつくろう」というお題から始まり、メンバー内で１００以上のアイデアが集まりました。<br className="pc"/>その中でメンバーの一人が「真っ白な缶バッジをつくり、そこにサインを書いてもらうのは？」と言い出し、<br className="pc"/>”これはイケる”と商品化をしたのです。<br className="pc"/>アイデアの量だけでなく、会議の場でどんどん新しいアイデアが膨らんでくるのも「笑ロジカル」の特徴です。
						</div>
						<div className="benefit-content-txt childScrollMagic sp" data-scrollmagic="l">
							「笑ロジカル」が企画開発した「かいてくだサインバッジ」。<br/>「新しい“缶バッジ”をつくろう」というお題から始まり、メンバー内で１００以上のアイデアが集まりました。<br/>その中でメンバーの一人が「真っ白な缶バッジをつくり、そこにサインを書いてもらうのは？」と言い出し、”これはイケる”と商品化をしたのです。<br/>アイデアの量だけでなく、会議の場でどんどん新しいアイデアが膨らんでくるのも「笑ロジカル」の特徴です。
						</div>
					</div>

					<div className="benefit-content middle scrollmagic parentScrollMagic" data-scrollmagic="R">
						<div className="benefit-content-title childScrollMagic pc" data-scrollmagic="r" data-delay="200">
							もりあがる！会議や打ち合わせに<br class="sp"/><span>「<span className="yellow">場をまわすトークを</span>」</span>
						</div>
						<div className="benefit-content-title childScrollMagic sp" data-scrollmagic="r">
							もりあがる！会議や打ち合わせに<br class="sp"/><span>「<span className="yellow">場をまわすトークを</span>」</span>
						</div>
						<div className="benefit-content-img pc">
							<div className="childScrollMagic" data-scrollmagic="r" data-delay="600">
								<img src="assets/img/comic2-1.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="r" data-delay="500">
								<img src="assets/img/comic2-2.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="r" data-delay="400">
								<img src="assets/img/comic2-3.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="r" data-delay="300">
								<img src="assets/img/comic2-4.gif"/>
							</div>
						</div>
						<div className="benefit-content-img sp benefit-slider">
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic2-1.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic2-2.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic2-3.gif"/>
							</div>
							<div className="childScrollMagic lastChildScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic2-4.gif"/>
							</div>
						</div>
						<div className="benefit-content-txt childScrollMagic pc" data-scrollmagic="r" data-delay="700">
							『「笑ロジカル」の皆さんに社内会議に参加して欲しい』というご相談をいただくことが増えてきました。<br className="pc"/>我々の自主調査では、メンバーが参加した会議全体の発言量は、<br className="pc"/>そうでない場合に比べて200％以上アップすることが確認できています。<br/>世間ばなしや議論の脱線からアイデアが生まれる経験をした方も多いと思います。<br/>名司会者に芸人さんが多いのも納得ですね。
						</div>
						<div className="benefit-content-txt childScrollMagic sp" data-scrollmagic="r">
							『「笑ロジカル」の皆さんに社内会議に参加して欲しい』というご相談をいただくことが増えてきました。<br/>我々の自主調査では、メンバーが参加した会議全体の発言量は、そうでない場合に比べて200％以上アップすることが確認できています。<br/>世間ばなしや議論の脱線からアイデアが生まれる経験をした方も多いと思います。<br/>名司会者に芸人さんが多いのも納得ですね。
						</div>
					</div>

					<div className="benefit-content last-child scrollmagic parentScrollMagic" data-scrollmagic="L">
						<div className="benefit-content-title childScrollMagic pc" data-scrollmagic="l" data-delay="200">
							おもしろい！あらゆる悩みや課題に<br class="sp"/><span>「<span className="yellow">笑いで解決を</span>」</span>
						</div>
						<div className="benefit-content-title childScrollMagic sp" data-scrollmagic="l">
							おもしろい！あらゆる悩みや課題に<br class="sp"/><span>「<span className="yellow">笑いで解決を</span>」</span>
						</div>
						<div className="benefit-content-img pc continueGif childScrollMagic" data-scrollmagic="l" data-delay="300">
							<div >
								<img src="assets/img/comic3-full.gif" alt=""/>
							</div>
						</div>
						<div className="benefit-content-img sp benefit-slider">
							<div className="childScrollMagic" data-scrollmagic="l">
								<img src="assets/img/comic3-1.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l" >
								<img src="assets/img/comic3-2.gif"/>
							</div>
							<div className="childScrollMagic" data-scrollmagic="l" >
								<img src="assets/img/comic3-3.gif"/>
							</div>
							<div className="childScrollMagic lastChildScrollMagic" data-scrollmagic="l" >
								<img src="assets/img/comic3-4.gif"/>
							</div>
						</div>
						<div className="benefit-content-txt childScrollMagic pc" data-scrollmagic="l" data-delay="700">
							「笑ロジカル」は、大手企業さんだけでなく、町役場の方、地域の商店さん、さらには個人の方の<br/>「どうしたらいいんだろう」に対し、真剣に楽しく応えています。<br/>著名なコンサルタントさんやクリエイターさんには、敵わないこともありますが<br/>「身近な相談相手」はオモシロイ人達の方がいいかなと思いまして（笑）
						</div>
						<div className="benefit-content-txt childScrollMagic sp" data-scrollmagic="l">
							「笑ロジカル」は、大手企業さんだけでなく、町役場の方、地域の商店さん、さらには個人の方の「どうしたらいいんだろう」に対し、真剣に楽しく応えています。<br/>著名なコンサルタントさんやクリエイターさんには、敵わないこともありますが「身近な相談相手」はオモシロイ人達の方がいいかなと思いまして（笑）
						</div>
					</div>
				</div>
			</section>
		)
	}
}