import React, {Component} from "react"

export default class What extends Component{
	render(){
		return(
			<section id="what">
				<div className="what-move">
					<span className="what-move-bulletL l01"></span>
					<span className="what-move-bulletM m01"></span>
					<span className="what-move-bulletM m02 pc"></span>
					<span className="what-move-bulletS s01"></span>
					<span className="what-move-bulletS s02 pc"></span>
					<span className="what-move-bulletS s03 pc"></span>
					<span className="what-move-bulletS s04 pc"></span>
					<span className="what-move-human h01"></span>
					<span className="what-move-human h02"></span>
					<span className="what-move-human h03 pc"></span>
					<span className="what-move-asteroid a01 pc"></span>
					<span className="what-move-asteroid a02"></span>
					<span className="what-move-fire f01 pc"></span>
					<span className="what-move-bottle b01"></span>
				</div>
				<div className="container">
					<div className="what-txt scrollmagic parentScrollMagic">
						<div className="what-txt-title">
							<img className="childScrollMagic pc" data-scrollmagic="L" src="assets/img/text-03.png"/>
							<img className="childScrollMagic sp" data-scrollmagic="b" src="assets/img/text-15.png"/>
						</div>
						<div className="what-txt-exp">
							<p className="childScrollMagic" data-scrollmagic="l" data-delay="300">
								「笑ロジカル」とは、“お笑いの技術”を文化芸術だけでなく、広くビジネスや社会貢献に役立てて頂くために新しく生まれた「アプローチ方法の用語」です。
							</p>
							<p className="childScrollMagic" data-scrollmagic="l" data-delay="400">
								“お笑いの技術”は、人がもつキャラクターやタレント（才能）による部分が多いのですが、「笑いが起きるメカニズム」は、心理学や生理学といった各方面の研究者によって、科学的に解明がなされています。
							</p>
							<p className="childScrollMagic what-txt-exp-center" data-scrollmagic="l" data-delay="500">
								つまり
								<span>「笑いはロジカルに活用できる！」</span>
							</p>
							<p className="childScrollMagic" data-scrollmagic="l" data-delay="600">
								<strong>オモシロイ仕事、オモシロイ企画、オモシロイ生活</strong>を目指し、<br class="pc"/>
								『今回のプロジェクトは「笑ロジカル」でいこう！』<br class="pc"/>
								こんな声が、あちらこちらから聞こえてくるように、皆さまと共に広めていきたいと考えています。
							</p>
						</div>
					</div>
				</div>
			</section>
		)
	}
}