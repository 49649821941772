import 'babel-polyfill'
import 'react-app-polyfill/ie9'
import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import ContactForm from './container/contactForm'
import Mv from './container/mv'
import What from './container/what'
import Benefit from './container/benefit'
import Member from './container/member'
import Price from './container/price'
import Notes from './container/notes'
import Footer from './container/footer'
import Header from './component/header'

export default class App extends Component {
	componentDidMount(){
		this.anchorScroll()
		this.loadInit()
		setTimeout(()=>{
			this.scrollMagic()
			this.accordion()
		},2000)


	}

	anchorScroll(){
		$(document).on('click', 'a[href^="#"]', function(event) {
			let diff = 0
			if(window.innerWidth <= 768){
				diff = 45
			}
			event.preventDefault()
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - diff
			}, 500);
		});
	}

	loadInit(){
		setTimeout(()=>{
			$('#noReactLoader').removeClass('loadInit')
		},3000)
	}

	scrollMagic(){
		let controller = new ScrollMagic.Controller();
		let targets = $('.scrollmagic');
		let scenes = [];

		targets.each(function() {
			let target = this;
			let scene = new ScrollMagic.Scene({triggerElement : target, triggerHook : 0.8});
			scene.on('enter', function(e) {
				$(target).addClass('magic')
				if($(target).hasClass('parentScrollMagic')){
					$(target).find('.childScrollMagic').addClass('magic')
				}
			});
			scene.on('leave', function(e) {
				// $(target).removeClass('magic')
			});

			scenes.push(scene);
		});
		controller.addScene(scenes);
	}

	accordion(){
		let $target = $('.header-body')
		let $anchor = $('.anchorLink')
		let pos = 0

		$('.header-head-btn').on('click', function(){
			console.log(pos)
			if($(this).hasClass('open')){
				$(this).removeClass('open')
				$('body').css({position: '', height: '', overflow: '', top: ''})
				$target.slideUp()
				$(window).scrollTop(pos)
			}
			else{
				pos = $(window).scrollTop()
				$(this).addClass('open')
				$('body').css({position: 'fixed', height: '100%', overflow: 'hidden', top: 0})
				$target.slideDown()
			}
		})


		$anchor.on('click', function(){
			$('.header-head-btn').removeClass('open')
			$('body').css({position: '', height: '', overflow: '', top: ''})
			$target.slideUp()
		})
	}


	render(){
		return(
			<div id="reactComponent">
				<Header/>
				<Mv/>
				<What/>
				<Benefit/>
				<Member/>
				<Price/>
				<Notes/>
				<ContactForm/>
				<Footer/>
			</div>
		)
	}
}


$(window).bind('load/image', ()=>{
	console.log('App start');
	$('#noReactLoader').find('.initLoader').addClass('animated fadeOut active')
	$('#noReactLoader').addClass('active')
	ReactDOM.render(
		<App/>,
		document.getElementById('root')
	)
});