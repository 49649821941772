import React,{Component} from 'react'

export default class Member extends Component{
	render(){
		return(
			<section id="member">
				<div className="container">
					<div className="scrollmagic parentScrollMagic" data-scrollmagic="b">
						<div className="member-title childScrollMagic" data-scrollmagic="b" data-delay="300">
							<img className="pc" src="assets/img/text-05.png"/>
							<img className="sp" src="assets/img/text-18.png"/>
						</div>
						<div className="member-exp">
							<p className="childScrollMagic" data-scrollmagic="b" data-delay="500">ボクは芸⼈として多くの⽅々に⽀えられ、成⻑してきました。特に周りの⼈間に恵まれ、助けてもらいながらやってきました。そんな仲間と⼀緒に「<strong>もっとたくさんの⼈と出会い笑顔を増やし幸せにしたい、仲間の才能を発揮する場所を増やしたい</strong>」 と考え、「笑ロジカル」というサービスを⽴ち上げました。</p>
							<p className="childScrollMagic" data-scrollmagic="b" data-delay="600">ボク達ができることをシンプルに提供し、⾃分たちの活躍の場を広げ、世の中に何か貢献できればと思ってます。ビジネスマンとしては勉強中の⾝ですが、まずは”<strong>⽑⾊のちがう、アイデア軍団</strong>”として、使ってもらえたら嬉しいです！</p>
							<p className="childScrollMagic" data-scrollmagic="b" data-delay="700">新しいご縁に恵まれましたらこれに変わる喜びはありません。何卒よろしくお願いします！</p>
						</div>
						<div className="member-crew sp scrollmagic" data-scrollmagic="b">
							<div>
								<img src="assets/img/icon-17.png"/>
							</div>
							<div>
								<img src="assets/img/icon-18.png"/>
							</div>
							<div>
								<img src="assets/img/icon-19.png"/>
							</div>
							<div>
								<img src="assets/img/icon-20.png"/>
							</div>
							<div>
								<img src="assets/img/icon-21.png"/>
							</div>
							<div>
								<img src="assets/img/icon-22.png"/>
							</div>
							<div>
								<img src="assets/img/icon-28.png"/>
							</div>
						</div>
						<div className="childScrollMagic pc" data-scrollmagic="R" data-delay="500">
							<div className="member-ship" >
								<img src="assets/img/img-23.png"/>
							</div>
						</div>
						<div className="member-move">
							<span className="member-move-bulletL l01"></span>
							<span className="member-move-bulletL l02 sp"></span>
							<span className="member-move-bulletL l03 sp"></span>
							<span className="member-move-bulletL l04 sp"></span>
							<span className="member-move-bulletL l05 sp"></span>
							<span className="member-move-bulletL l06"></span>
							<span className="member-move-bulletL l07"></span>
							<span className="member-move-bulletL l08"></span>
							<span className="member-move-bulletL l09"></span>
							<span className="member-move-bulletM m01"></span>
							<span className="member-move-bulletM m02"></span>
							<span className="member-move-bulletM m03 sp"></span>
							<span className="member-move-bulletM m04 sp"></span>
							<span className="member-move-bulletM m05"></span>
							<span className="member-move-bulletM m06"></span>
							<span className="member-move-bulletS s01"></span>
							<span className="member-move-bulletS s02"></span>
							<span className="member-move-bulletS s03"></span>
							<span className="member-move-bulletS s04"></span>
							<span className="member-move-bulletS s05"></span>
							<span className="member-move-bulletS s06 sp"></span>
							<span className="member-move-bulletS s07"></span>
							<span className="member-move-bulletS s08"></span>
							<span className="member-move-asteroid a01"></span>
							<span className="member-move-asteroid a02"></span>
						</div>
					</div>
				</div>
			</section>
		)
	}
}