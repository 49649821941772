import React,{Component} from 'react'

export default class Header extends Component{
	share(e, link){
		e.preventDefault()
		window.open(link,"social_window","width=600,height=600,resizable=yes,scrollbars=yes,toolbar=yes")
	}

	componentDidUpdate(){
		const userAgent = window.navigator.userAgent

		$(window).on('touchmove', ()=>{
			try {
				if($('.header-head-btn').hasClass('open')){
					document.body.scrollTop = -1
				}

			} catch (e) {
				console.log(e.message)
			}
		})
	}

	render(){
		return(
			<header id="header" className="sp">
				<div className="container">
					<div className="header-head">
						<div className="header-head-logo">
							<a href="#mv"><img src="assets/img/svg-01.svg" alt=""/></a>
						</div>
						<div className="header-head-contact">
							<a href="#contact">お問合せ</a>
						</div>
						<div className="header-head-btn">
							<div class="header-head-btn-icon"><span/><span/><span/></div>
						</div>
					</div>
					<div className="header-body">
						<div className="header-body-move">
							<span className="header-body-move-bulletM m01"></span>
							<span className="header-body-move-bulletM m02"></span>
							<span className="header-body-move-bulletM m03"></span>
							<span className="header-body-move-bulletS s01"></span>
							<span className="header-body-move-bulletS s02"></span>
							<span className="header-body-move-bulletS s03"></span>
							<span className="header-body-move-bulletS s04"></span>
							<span className="header-body-move-bulletS s05"></span>
							<span className="header-body-move-asteroid a01"></span>
							<span className="header-body-move-asteroid a02"></span>
						</div>
						<ul>
							<li>
								<a className="anchorLink" href="#mv">TOP</a>
							</li>
							<li>
								<a className="anchorLink" href="#benefit">サービス</a>
							</li>
							<li>
								<a className="anchorLink" href="#member">メンバー紹介</a>
							</li>
							<li>
								<a className="anchorLink" href="#price">料金</a>
							</li>
							<li>
								<a className="anchorLink" href="#notes">ご利用について</a>
							</li>
							<li>
								<a className="anchorLink anchorLinkContact" href="#contact">お問合せ</a>
							</li>
							<li>
								<div className="helvetica">
									Share
								</div>
								<div className="sns">
									<a href="https://twitter.com/share?url=https://walogical.com" onClick={(e)=>this.share(e,'https://twitter.com/share?url=https://walogical.com')}><img className="twitter" src="assets/img/svg-05.svg" alt=""/></a>
									<a href="https://www.facebook.com/sharer/sharer.php?u=https://walogical.com" style={{zIndex: 6}}onClick={(e)=>this.share(e,'https://www.facebook.com/sharer/sharer.php?u=https://walogical.com')}><img className="fb" src="assets/img/svg-06.svg" alt=""/></a>
									<a href="http://line.me/R/msg/text/?https://walogical.com" onClick={(e)=>this.share(e,'http://line.me/R/msg/text/?https://walogical.com')}><img className="line" src="assets/img/svg-07.svg" alt=""/></a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</header>
		)
	}
}